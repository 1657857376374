<template>
  <v-container>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <v-text-field
              width="200px"
              outlined
              hide-details
              dense
              placeholder="Search orders"
              v-model.trim="search"
            >
            </v-text-field>
          </v-col>

          <v-col align-self="center">
            <v-btn
              depressed
              color="primary"
              :disabled="!search"
              :loading="loading"
              @keyup.enter="initSearch"
              @click="initSearch"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-skeleton-loader
      class="my-5"
      type="list-item-two-line"
      v-if="loading"
    ></v-skeleton-loader>

    <v-card v-else-if="orders.length > 0" outlined class="my-5">
      <v-card-text>
        <div>
          <v-row class="font-weight-bold">
            <v-col class="order_details">
              <div v-for="(details, index) in orders" :key="`r1-${index}`">
                <div>
                  Brand: <span>{{ details.brand }}</span>
                </div>

                <div>
                  Coupon: <span>{{ details.coupon }}</span>
                </div>

                <div>
                  Order gross: <span>{{ details.order_gross }}</span>
                </div>

                <div>
                  Order Id: <span> {{ details.order_id }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              v-for="(details, index) in orders"
              :key="`r2-${index}`"
              align="end"
              class="order_details"
            >
              <div v-for="(details, index) in orders" :key="`r1-${index}`">
                <div>
                  Client:
                  <span>{{ details.client_name }}</span>
                </div>
                <div>
                  Net Revenue:
                  <span>{{ details.net_revenue_aed }}</span>
                </div>
                <div>
                  Gross Revenue:
                  <span> {{ details.gross_revenue_aed }}</span>
                </div>
                <div>
                  Revenue gross:
                  <span> {{ details.revenue_gross }}</span>
                </div>
                <div>
                  Revenue net:
                  <span>{{ details.revenue_net }}</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2" v-if="orders.length"></v-divider>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      loading: false,
      orders: [
        // {
        //   client_id: 99,
        //   client_name: "Mother Care",
        //   order_id: "MCSAHDA2429583-T9090-99",
        //   order_status: null,
        //   currency: "USD",
        //   brand: "Mother Care",
        //   coupon: "T9090",
        //   revenue_gross: 27.8,
        //   revenue_net: 27,
        //   order_gross: "1",
        //   order_net: "1",
        //   gross_revenue_aed: "99.63",
        //   net_revenue_aed: "99.63",
        // },
      ],
    };
  },
  methods: {
    initSearch() {
      this.loading = true;
      this.$api
        .get_base2(`/api/order/${this.search}`)
        .then((response) => {
          if (response.data.data.length == 0) {
            this.$toast.open({
              message: "No data found.",
              type: "error",
              duration: 3000,
              position: "top-right",
              queue: true,
            });

            throw new Error("No data found.");
          }

          this.loading = false;
          this.search = null;
          this.orders = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order_details {
  div {
    span {
      font-weight: 400;
    }
  }
}
</style>